import { useMutation } from 'react-query';
import api from '../../api/api';

async function deleteUser(id) {
  const response = await api
    .delete('/users/' + id)
    .then((response) => response)
    .catch((error) => console.log(error));
  return response.data;
}

export function useDeleteUser() {
  return useMutation(
    async (data) => {
      await deleteUser(data.id);
    },
    {
      onSuccess: () => {},
      onError: () => {},
    }
  );
}
